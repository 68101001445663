import React, { Component } from 'react';
// import axios from 'axios';
import UserStore from '../flux/UserStore';
// import DirStore from '../flux/DirStore';

export default class ZipCleanup extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      // iconClass: IconClass.getClassName(props.item.extension),
      wpBase: UserStore.getWpBase(),
      pluginBase: UserStore.getPluginBase(),
      thumb: false,
    };
  }

  render() {
    // console.log(this.state.item);
    return (
      <span className="title">
        things to cleanup.
      </span>
    );
  }
}
