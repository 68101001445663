import React, { Component } from 'react';

export default class PDFsvg extends Component {
  render() {
    const aStyle = { fill: '#fff' };
    const bStyle = { fill: '#ee393a' };
    return (
      <div className="thumb thumb-pdf">
        <svg viewBox="0 0 292.01 367.74">
          <title>pdf</title>
            <path className="a" style={aStyle} d="M216.89 13.43A15.4 15.4 0 0 0 206 9H24.5A15.51 15.51 0 0 0 9 24.5v202.33h274V84.75a15.6 15.6 0 0 0-4.65-11.07zM198.81 153.8l-50.51 51.92a3 3 0 0 1-4.3 0L93.21 153.8a3 3 0 0 1 2.14-5.1H118a3 3 0 0 0 3-3V26.46a3 3 0 0 1 3-3h44a3 3 0 0 1 3 3V145.7a3 3 0 0 0 3 3h22.66a3 3 0 0 1 2.15 5.1zM71.06 253.89H30.39v85.68H52.7V311h18.36c18.84 0 31-8.28 31-29.52-.06-20.27-15.06-27.59-31-27.59zm-3.36 39.84h-15v-22h15c6.84 0 12 3.24 12 11.28 0 7.84-5.04 10.72-12 10.72zM149.42 253.89h-36.84v85.68h36.84c26 0 42-15.12 42-43.2 0-26.52-15.72-42.48-42-42.48zm-4 66.48H134.9v-47.28h10.56c15.24 0 23.64 7.56 23.64 23.16 0 17.16-6.72 23.75-23.64 24.12zM203.66 339.57h22.32v-33h35.28v-17.28h-35.28v-17.52h40.8v-17.88h-63.12v85.68z"/>
            <path className="b" style={bStyle} d="M196.65 148.7H174a3 3 0 0 1-3-3V26.46a3 3 0 0 0-3-3h-44a3 3 0 0 0-3 3V145.7a3 3 0 0 1-3 3H95.35a3 3 0 0 0-2.14 5.1L144 205.73a3 3 0 0 0 4.3 0l50.51-51.92a3 3 0 0 0-2.16-5.11z"/>
            <path className="b" style={bStyle} d="M284.66 67.25L223.19 7A24.39 24.39 0 0 0 206 0H24.5A24.53 24.53 0 0 0 0 24.5v318.74a24.53 24.53 0 0 0 24.5 24.5h243a24.53 24.53 0 0 0 24.5-24.5V84.75a24.66 24.66 0 0 0-7.34-17.5zM283 226.83H9V24.5A15.51 15.51 0 0 1 24.5 9H206a15.4 15.4 0 0 1 10.85 4.43l61.47 60.25A15.6 15.6 0 0 1 283 84.75zM30.39 339.57v-85.68h40.67c16 0 31 7.32 31 27.6 0 21.24-12.12 29.52-31 29.52H52.7v28.56zm82.19 0v-85.68h36.84c26.28 0 42 16 42 42.48 0 28.08-16 43.2-42 43.2zm154.2-67.8H226v17.52h35.28v17.28H226v33h-22.34v-85.68h63.12z"/>
            <path className="b" style={bStyle} d="M145.46 273.09H134.9v47.28h10.56c16.92-.36 23.64-7 23.64-24.12 0-15.6-8.4-23.16-23.64-23.16zM79.7 283.05c0-8-5.16-11.28-12-11.28h-15v22h15c6.96-.04 12-2.92 12-10.72z"/>
        </svg>
      </div>
    );
  }
}
