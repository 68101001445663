import { EventEmitter } from 'fbemitter';

const emitter = new EventEmitter();
const urlBase = 'https://ftplibrary.smartlivinghg.com/getinfoPHP/';
let allDirs = [];
let selectedDir = '';
let currentLoadedDir = undefined;
let sortBy = 'name';
let sortDirection = 'low2high';
let dirToDownload = []; // selected dir to download

const DirStore = {
  getUrlBase() { return urlBase; },

  setDirContents(dir) {
    allDirs[dir.linkPath] = dir;
    emitter.emit('loadDir');
  },
  getAllDirs(): Array<Object> { return allDirs; },

  setSelectedDir(path) {
    selectedDir = path;
    emitter.emit('selectedDir');
  },
  getSelectedDir() { return selectedDir; },

  setCurrentLoadedDir(array) {
    currentLoadedDir = array;
    emitter.emit('loadedDir');
  },

  getCurrentLoadedDir(): Array<Object> { return currentLoadedDir; },

  setSortBy(attribute) {
    sortBy = attribute;
    emitter.emit('sortBy');
  },
  setSortDirection(string) {
    sortDirection = string;
    emitter.emit('sortDirection');
  },
  reverseSortDirection() {
    if (sortDirection === 'low2high') {
      sortDirection = 'high2low';
    } else {
      sortDirection = 'low2high';
    }
    emitter.emit('sortDirection');
  },

  getSortBy() { return sortBy; },
  getSortDirection() { return sortDirection; },

  setDirToDownload(dirObj) {
    dirToDownload = dirObj;
    emitter.emit('dirToDownload');
  },
  getDirToDownload(): Array<Object> { return dirToDownload; },


  addListener(eventType: string, fn: Function) {
    return emitter.addListener(eventType, fn);
  },
};

export default DirStore;
