import React, { Component } from 'react';
// import axios from 'axios';
import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';

export default class ClearCartButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      // item: props.item,
      // iconClass: IconClass.getClassName(props.item.extension),
      wpBase: UserStore.getWpBase(),
      pluginBase: UserStore.getPluginBase(),
      thumb: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.clearCart && this.clearCart.contains(e.target)) {
      e.preventDefault();
      ModalStore.setModal('clear-cart');
    }
  }

  render() {
    return (
      <a
        href="clear-cart"
        className="clear-cart"
        ref={(clearCart) => { this.clearCart = clearCart; }}
      >
        <i className="fas fa-minus-circle" /> Clear Cart.
      </a>
    );
  }
}
