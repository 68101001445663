import React, { Component } from 'react';
import axios from 'axios';
import ModalStore from '../flux/ModalStore';
import UserStore from '../flux/UserStore';

export default class ClearCartModalContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
      status: 'standby',
      wpBase: UserStore.getWpBase(),
      userInfo: UserStore.getUserInfo(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (
      (this.closeBtn && this.closeBtn.contains(e.target)) ||
      (this.bgBtn && e.target === this.bgBtn)
    ) {
      e.preventDefault();
      ModalStore.modalReset();
    }

    if (
      (this.clearCart && this.clearCart.contains(e.target)) &&
      this.state.status === 'standby'
    ) {
      this.clearTheCart();
    }
  }

  clearTheCart() {
    this.setState({ status: 'waiting' });
    let params = new URLSearchParams();
    params.append('rowID', this.state.userInfo.cart.id);
    params.append('userID', this.state.userInfo.id);

    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/clearCart`, params)
      .then((response) => {
        console.log(response.data);
        if (response.data === 'success') {
          UserStore.deleteCart();
          ModalStore.modalReset();
        };
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const cartCount = UserStore.getCart().length;
    return (
      <div className="modal-content">
        <div
          className="bg-close-btn"
          ref={(bgBtn) => { this.bgBtn = bgBtn; }}
        />
        <span className="user-modal">
          Are you sure you want to remove {cartCount > 1 ? 'all' : ''} {cartCount} file{cartCount > 1 ? 's' : ''} from your cart?
          <button
            href="empty-cart"
            ref={(clearCart) => { this.clearCart = clearCart; }}
            className={this.state.status}
          >
            Empty Cart
          </button>
          <a
            href="close"
            ref={(closeBtn) => { this.closeBtn = closeBtn; }}
            className="close-button"
          >
            <i className="far fa-times-circle" />
          </a>
        </span>
      </div>
    );
  }
}
