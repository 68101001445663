import React, { Component } from 'react';
import axios from 'axios';
import ModalStore from '../flux/ModalStore';
import UserStore from '../flux/UserStore';

export default class DownloadCartModalStatus extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
      wpBase: UserStore.getWpBase(),
      userInfo: UserStore.getUserInfo(),
      zipFile: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    this.requestZipFile();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (
      (this.closeBtn && this.closeBtn.contains(e.target)) ||
      (this.bgBtn && e.target === this.bgBtn)
    ) {
      e.preventDefault();
      ModalStore.modalReset();
    }
  }

  requestZipFile() {
    axios.get(`${this.state.wpBase}wp-json/slhgFTP/v1/collectFiles`)
      .then((response) => {
        this.setState({ zipFile: response.data });
        UserStore.updateCart([]);
        UserStore.updateCartArray([]);
      })
      .catch((error) => {
        console.log(error);
      });

    // axios.get(`${this.state.wpBase}wp-json/slhgFTP/v1/cartStatus`)
    //   .then((response) => {
    //     this.setState({ zipFile: response.data });
    //     UserStore.updateCart([]);
    //     UserStore.updateCartArray([]);
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
  }

  renderLoading() {
    return (
      <ul>
        <li>
          <h1><i className="fas fa-cog fa-spin" /></h1>
        </li>
        <li>
          Collecting files. Please be patient,<br />
          as this may take a few minutes.
        </li>
      </ul>
    );
  }

  renderDl() {
    let link = `${this.state.wpBase}wp-json/slhgFTP/v1/dlZip?file=`;
    link = `${link}${this.state.zipFile.zipFilename}`;
    return (
      <ul>
        <li>
          <a href={link}>
            Click here to download
          </a>
        </li>
        <li>({this.state.zipFile.sizeR})</li>
      </ul>
    );
  }

  render() {
    return (
      <div className="modal-content">
        <div
          className="bg-close-btn"
          ref={(bgBtn) => { this.bgBtn = bgBtn; }}
        />
        <span className="user-modal">
          {this.state.zipFile ? this.renderDl() : this.renderLoading()}
          <a
            href="close"
            ref={(closeBtn) => { this.closeBtn = closeBtn; }}
            className="close-button"
          >
            <i className="far fa-times-circle" />
          </a>
        </span>
      </div>
    );
  }
}
