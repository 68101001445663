import React, { Component } from 'react';
import DirStore from '../flux/DirStore';
import Breadcrumbs from './Breadcrumbs';
import { BrowserRouter as Router, Link } from "react-router-dom";

export default class BrowserHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    this.emitter0 = DirStore.addListener('loadedDir', () => {
      this.setTitle();
    });
    this.setTitle();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    this.emitter0.remove();
  }

  setTitle() {
    if (DirStore.getCurrentLoadedDir().linkPath === '') {
      this.setState({ title: 'FTP' });
    } else {
      this.setState({ title: DirStore.getCurrentLoadedDir().name });
    }
  }

  handleClick(e) {
    if (this.rootLink && this.rootLink.contains(e.target)) {
      e.preventDefault();
      DirStore.setSelectedDir('');
    }
  }

  render() {
    const breadcrumbs = DirStore.getCurrentLoadedDir().breadcrumbs;
    return (
      <header className="browser-header">
        <h1>{this.state.title}</h1>
        <Router>
          <div className="breadcrumbs">
            <span ref={(rootLink) => { this.rootLink = rootLink; }}>
              <Link to="/"><i className="fas fa-home" /></Link>
            </span>
            {breadcrumbs ? <Breadcrumbs /> : ''}
          </div>
        </Router>
      </header>
    );
  }
}
