import React, { Component } from 'react';
import axios from 'axios';
import UserStore from '../flux/UserStore';
import IconClass from '../actions/IconClass';
import DownloadButtons from './DownloadButtons';
import PDFsvg from './PDFsvg';

export default class TitleFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      iconClass: IconClass.getClassName(props.item.extension),
      wpBase: UserStore.getWpBase(),
      pluginBase: UserStore.getPluginBase(),
      thumb: false,
    };
  }

  componentDidMount() {
    if (this.state.item.extension === 'jpg') {
      this.loadThumb();
    }
  }

  renderIcon() {
    if (this.state.item.extension === 'pdf') { return <PDFsvg />; }
    return <i className={`${this.state.iconClass} item-icon`} />;
  }

  renderThumb() {
    const url = `${this.state.pluginBase}__thumbnails/${this.state.thumb}`;
    return <img src={url} alt="thumb" className="thumb" />;
  }

  loadThumb() {
    let params = new URLSearchParams();
    params.append('path', this.state.item.linkPath);
    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/thumb`, params)
      .then((response) => {
        if (response.data) {
          this.setState({ thumb: response.data });
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <span className="title">
        {this.state.thumb ? this.renderThumb() : this.renderIcon()}
        <span className="ttl">{this.state.item.name}</span>
        <DownloadButtons item={this.state.item} />
      </span>
    );
  }
}
