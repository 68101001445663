import React, { Component } from 'react';
import axios from 'axios';
import DirStore from '../flux/DirStore';
import UserStore from '../flux/UserStore';

export default class DownloadButtons extends Component {

  constructor(props) {
    super(props);
    // console.log(props.item.linkPath);
    this.state = {
      urlBase: DirStore.getUrlBase(),
      wpBase: UserStore.getWpBase(),
      linkPath: props.item.linkPath,
      page: props.page,
      itemInCart: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    this.emitter0 = UserStore.addListener('cartChange', () => {
      // console.log('cartChange');
      this.checkStatus();
    });
    this.checkStatus();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    this.emitter0.remove();
  }

  handleClick(e) {
    if (this.add2cart && this.add2cart.contains(e.target)) {
      e.preventDefault();
      this.addItem2Cart(this.state.linkPath);
    }
  }

  checkStatus() {
    const cart = UserStore.getCart();
    let inCart = false;
    for (var i = 0; i < cart.length; i = (i + 1)) {
      if (this.state.linkPath === cart[i]) { inCart = true; }
    }
    this.setState({ itemInCart: inCart });
  }

  addItem2Cart(path) {
    console.log(path);
    let params = new URLSearchParams();
    const action = this.state.itemInCart ? 'removeItem' : 'addItem';
    params.append(action, path);
    params.append('userID', UserStore.getUserInfo().id);

    // console.log(`${this.state.wpBase}wp-json/slhgFTP/v1/cart`);
    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/cart`, params)
      .then((response) => {
        console.log(response.data);
        if (response.data === 'logged_out') { UserStore.logOut(); }
        UserStore.updateCart(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  renderDownload() {
    let link = `${this.state.wpBase}wp-json/slhgFTP/v1/dlSingleFile?file=`;
    link = `${link}${this.state.linkPath}`;
    console.log(link);
    return (
      <span className="dl">
        <a href={link}><i className="fas fa-download" /></a>
      </span>
    );
  }

  render() {
    const pageName = this.state.page;
    return (
      <div className="download-buttons">
        {pageName === 'cart' ? '' : this.renderDownload()}
      </div>
    );
  }
}
