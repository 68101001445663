import React, { Component } from 'react';
import axios from 'axios';
import ModalStore from '../flux/ModalStore';
import DirStore from '../flux/DirStore';
import UserStore from '../flux/UserStore';

export default class ClearCartModalContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
      status: 'standby',
      wpBase: UserStore.getWpBase(),
      directory: DirStore.getDirToDownload(),
      filename: '',
      tempFilename: false,
      tempFilesize: 0,
      tempFilesizeR: '0 bytes',
      dlPath: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    this.emitter0 = DirStore.addListener('dirToDownload', () => {
      this.setState({ directory: DirStore.getDirToDownload() });
    });
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    this.emitter0.remove();
  }

  handleClick(e) {
    if (
      (this.closeBtn && this.closeBtn.contains(e.target)) ||
      (this.bgBtn && e.target === this.bgBtn)
    ) {
      e.preventDefault();
      ModalStore.modalReset();
    }

    if (
      (this.clearCart && this.clearCart.contains(e.target)) &&
      this.state.status === 'standby'
    ) {
      const user = UserStore.getUserInfo();
      if (user.id) {
        this.downloadDir(user.id);
      } else {
        console.log('no user ID');
      }
    }

    if ( this.dlBtn && this.dlBtn.contains(e.target) ) {
      e.preventDefault();
      console.log('dl button');
      this.downloadZipFile();
    }
  }

  downloadDir(userID) {
    const filename =
      `${this.state.directory.name}_${this.state.directory.mktime}.zip`;
    this.setState({ status: 'waiting', filename });
    console.log(this.state.directory.linkPath);
    let params = new URLSearchParams();
    params.append('dirName', this.state.directory.name);
    params.append('fileName', filename);
    params.append('decodedPath', this.state.directory.decodedPath);
    params.append('mktime', this.state.directory.mktime);
    params.append('dir', this.state.directory);
    params.append('linkPath', this.state.directory.linkPath);

    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/downloadFolder`, params)
      .then((response) => {
        this.setState({ status: 'done', dlPath: response.data.path });
        this.downloadZipFile();
      })
      .catch((error) => {
        console.log(error);
      });

    this.getZipStatus();
  }

  getZipStatus() {
    let statusParams = new URLSearchParams();
    statusParams.append('tempFileName', this.state.tempFileName);
    statusParams.append('fileName', this.state.filename);
    statusParams.append('decodedPath', this.state.directory.decodedPath);
    axios.post(
      `${this.state.wpBase}wp-json/slhgFTP/v1/cartStatus`,
      statusParams
    )
      .then((response) => {
        if (response.data.name) {
          if (
            response.data.name !== this.state.tempFileName ||
            response.data.sizeR !== this.state.sizeR
          ) {
            this.setState({
              tempFileName: response.data.name,
              tempFilesize: response.data.size,
              tempFilesizeR: response.data.sizeR,
            });
          }
        }
        if (this.state.status === 'waiting') {
          setTimeout(() => { this.getZipStatus(); }, 400);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  downloadZipFile() {
    if (this.state.dlPath) {
      let url = `${this.state.wpBase}wp-content/plugins/slhg-ftp/`;
          url = `${url}${this.state.dlPath}`;
      window.location.replace(url);
    }
  }

  renderConfirm() {
    return (
      <span>
        {this.state.directory.allFiles.length} files, {this.state.directory.sizeR}
        <button
          href="download"
          ref={(clearCart) => { this.clearCart = clearCart; }}
          className={this.state.status}
        >
          Download {this.state.directory.name}
        </button>
      </span>
    );
  }

  renderProgress() {
    const percentage =
      (this.state.tempFilesize / this.state.directory.size) *100;
    const percentageR = percentage.toFixed();
    return (
      <ul>
        <li><i className="fas fa-cog fa-spin" /></li>
        <li>Collecting files:</li>
        <li>{percentageR}% processed ({this.state.tempFilesizeR})</li>
      </ul>
    );
  }

  renderDownload() {
    return (
      <ul>
        <li>If the download doesn't start automatically,</li>
        <button
          href="download"
          ref={(dlBtn) => { this.dlBtn = dlBtn; }}
        >
          click here
        </button>
      </ul>
    );
  }

  render() {
    return (
      <div className="modal-content">
        <div
          className="bg-close-btn"
          ref={(bgBtn) => { this.bgBtn = bgBtn; }}
        />
        <span className="user-modal">
          {this.state.status === 'standby' ? this.renderConfirm() : ''}
          {this.state.status === 'waiting' ? this.renderProgress() : ''}
          {this.state.status === 'done' ? this.renderDownload() : ''}
          <a
            href="close"
            ref={(closeBtn) => { this.closeBtn = closeBtn; }}
            className="close-button"
          >
            <i className="far fa-times-circle" />
          </a>
        </span>
      </div>
    );
  }
}
