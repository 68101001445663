import React, { Component } from 'react';
// import axios from 'axios';
import UserStore from '../flux/UserStore';
import DirStore from '../flux/DirStore';
import IconClass from '../actions/IconClass';
import DownloadButtons from './DownloadButtons';

export default class CartFile extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      iconClass: IconClass.getClassName(props.item.extension),
      wpBase: UserStore.getWpBase(),
      pluginBase: UserStore.getPluginBase(),
      thumb: false,
    };
  }

  render() {
    // console.log(this.state.item);
    return (
      <span className="title">
        <i className={`${this.state.iconClass} item-icon`} />
        <span className="ttl">{this.state.item.name}</span>
        <DownloadButtons
          item={this.state.item}
          page={DirStore.getSelectedDir()}
        />
      </span>
    );
  }
}
