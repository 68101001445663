
const SortList = {
  arrange(list, sortBy, sortDirection) {
    let sorted = [];
    if (sortBy === 'type') { sortBy = 'extension'; };




    for (var key in list) { sorted.push(list[key]); };
    sorted.sort(function(a, b) {
      return a[sortBy] < b[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0;
    });

    if (sortDirection === 'low2high') { sorted.reverse(); }


    return sorted;
  },
};

export default SortList;
