import { EventEmitter } from 'fbemitter';

const emitter = new EventEmitter();
const wpBase = 'https://ftplibrary.smartlivinghg.com/wp/';
// const wpBase = 'http://192.168.1.110/~Alex/new/slhg-FTP-library/wp/';
// const wpBase = 'http://192.168.0.100/new/ftp/wp/';
const pluginBase = `${wpBase}wp-content/plugins/slhg-ftp/`;
// const pluginBase = 'https://ftplibrary.smartlivinghg.com/wp/wp-content/plugins/slhg-ftp/';
let loginState = 'initializing';
let userInfo = [];
let cart = [];
let cartArray = [];

const UserStore = {
  getWpBase() { return wpBase; },
  getPluginBase() { return pluginBase; },

  setLoginState(state) {
    // initializing, waiting, error, logged_in, logged_out
    loginState = state;
    emitter.emit('loginState');
  },

  logOut() {
    loginState = 'logged_out';
    emitter.emit('loginState');
  },

  getLoginState() { return loginState; },

  setUserInfo(array) {
    if (array.cart && array.cart.cart) {
      // parse cart from database:
      this.updateCart(JSON.parse(array.cart.cart));
    }
    userInfo = array;
    emitter.emit('userInfo');
  },

  deleteCart() {
    this.updateCart([]);
    this.updateCartArray([]);
  },

  updateCart(response) {
    cart = response;
    // console.log(cart);
    emitter.emit('cartChange');
  },
  getCart(): Array<Object> { return cart; }, // simple cart array

  updateCartArray(response) {
    // response = object from PHP describing items
    cartArray = response;
    // console.log(cartArray);
    emitter.emit('cartArrayChange');
  },
  getCartArray(): Array<Object> {
    // full item info for cart page:
    return cartArray;
  },

  getUserInfo(): Array<Object> { return userInfo; },

  addListener(eventType: string, fn: Function) {
    return emitter.addListener(eventType, fn);
  },
};

export default UserStore;
