import React, { Component } from 'react';
import DirStore from '../flux/DirStore';
import TitleFolder from './TitleFolder';
import TitleFile from './TitleFile';
import SortButton from './SortButton';
import SortList from '../actions/SortList';
import { BrowserRouter as Router } from "react-router-dom";

export default class SelectedContents extends Component {

  constructor() {
    super();
    this.state = {
      contents: DirStore.getCurrentLoadedDir(),
      sortBy: DirStore.getSortBy(),
      sortDirection: DirStore.getSortDirection(),
    };
  }

  componentDidMount() {
    this.emitter0 = DirStore.addListener('loadedDir', () => {
      this.setState({ contents: DirStore.getCurrentLoadedDir(), });
    });
    this.emitter1 = DirStore.addListener('sortBy', () => {
      this.checkSorting();
    });
    this.emitter2 = DirStore.addListener('sortDirection', () => {
      this.checkSorting();
    });
  }

  componentWillUnmount() {
    this.emitter0.remove();
    this.emitter1.remove();
    this.emitter2.remove();
  }

  checkSorting() {
    const sortBy = DirStore.getSortBy();
    const sortDirection = DirStore.getSortDirection();

    if (
      this.state.sortBy !== sortBy ||
      this.state.sortDirection !== sortDirection
    ) {
      this.setState({ sortBy, sortDirection });
    }

  }

  sortContents() {
    const list = this.state.contents.contents;
    const sortDirection = DirStore.getSortDirection();
    let sortBy = DirStore.getSortBy();
    if (sortBy === 'type') { sortBy = 'extension'; };
    let sorted = [];

    for (var key in list) { sorted.push(list[key]); };
    sorted.sort(function(a, b) {
      return a[sortBy] < b[sortBy] ? 1 : a[sortBy] > b[sortBy] ? -1 : 0;
    });

    if (sortDirection === 'low2high') { sorted.reverse(); }
    return sorted;
  }

  render() {
    if (this.state.contents) {
      const list = SortList.arrange(
        this.state.contents.contents,
        this.state.sortBy,
        this.state.sortDirection,
      );
      return (
        <section>
          <span className="sort-by">
            <SortButton title="name" />
            <SortButton title="type" />
            <SortButton title="size" />
          </span>
          <Router>
            <ul>
              {list.map((item) => {
                return (
                  <li key={item.linkPath}>
                    {
                      item.extension === 'folder' ?
                      <TitleFolder item={item} /> :
                      <TitleFile item={item} />
                    }
                    <span className="type">{item.extension}</span>
                    <span className="size">
                      {item.sizeR}
                    </span>
                  </li>
                );

              }, this)}
            </ul>
          </Router>
        </section>
      );
    }
    return false;
  }
}
