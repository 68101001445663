import React, { Component } from 'react';
// import axios from 'axios';
import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';
import LoginForm from './LoginForm';

export default class LoginModal extends Component {

  constructor() {
    super();
    this.state = {
      loginState: UserStore.getLoginState(),
      modalState: 'on',
    };
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('loginState', () => {
      const loginState = UserStore.getLoginState();

      this.setState({ loginState });
      if (loginState === 'logged_in') {
        ModalStore.modalReset();
        setTimeout(() => {
          this.setState({ modalState: 'off' });
        }, 250);
      };
      if (loginState === 'logged_out' && this.state.modalState === 'off') {
        this.setState({ modalState: 'on' });
        ModalStore.setModal('login');
      }
    });
    ModalStore.setModal('login');
  }

  componentWillUnmount() {
    this.emitter0.remove();
  }

  render() {
    // console.log(this.state.loginState);
    return (
      <span className={this.state.modalState === 'off' ? 'modal-off' : 'modal'}>
        <div
          className={
            this.state.loginState === 'logged_in' ?
            'modal-bg-off' :
            'modal-bg modal-bg-login'
          }
        >
          {this.state.loginState !== 'logged_in' ? <LoginForm /> : ''}
        </div>
      </span>
    );
  }
}
