import React, { Component } from 'react';
// import axios from 'axios';
import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';

export default class AccountButton extends Component {

  constructor() {
    super();
    this.state = {
      userInfo: UserStore.getUserInfo(),
      allModals: ModalStore.getAllModals(),
    };
    this.handleClick = this.handleClick.bind(this);
    // this.handleKeyboard = this.handleKeyboard.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
    // document.addEventListener('keyup', this.handleKeyboard);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
    // document.removeEventListener('keyup', this.handleKeyboard);
  }

  handleClick(e) {
    if (this.account && this.account.contains(e.target)) {
      e.preventDefault();
      ModalStore.setModal('account');
    }
  }

  render() {
    return (
      <button
        className="user-button account"
        href="account"
        ref={(account) => { this.account = account; }}
      >
        {this.state.userInfo.email}
      </button>
    );
  }
}
