import React, { Component } from 'react';
// import axios from 'axios';
import ModalStore from '../flux/ModalStore';

export default class CloseModalButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if ( this.closeBtn && this.closeBtn.contains(e.target) ) {
      e.preventDefault();
      ModalStore.removeModal(this.state.modalName);
    }
  }

  render() {
    return (
      <a
        href="close"
        ref={(closeBtn) => { this.closeBtn = closeBtn; }}
        className="close-button"
      >
        <i className="far fa-times-circle" />
      </a>
    );
  }
}
