import React, { Component } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import DirStore from '../flux/DirStore';
import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';

class TitleFolder extends Component {

  constructor(props) {
    super(props);
    this.state = {
      item: props.item,
      iconClass: 'fas fa-folder',
      wpBase: UserStore.getWpBase(),
    };
    this.handleClick = this.handleClick.bind(this);
    this.mouseOver = this.mouseOver.bind(this);
    this.mouseOut = this.mouseOut.bind(this);
    this.testFunc = this.testFunc.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }
  //
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.folderLink && this.folderLink.contains(e.target)) {
      e.preventDefault();
      // console.log(this.state.item.linkPath);
      DirStore.setSelectedDir(this.state.item.linkPath);
    }

    if (this.addAll && this.addAll.contains(e.target)) {
      e.preventDefault();
      this.submitFolder();
    }

    if (this.downloadAll && this.downloadAll.contains(e.target)) {
      e.preventDefault();
      // this.submitFolder();
      DirStore.setDirToDownload(this.state.item);
      ModalStore.setModal('downloadDir');
      // console.log(this.state.item);
    }
  }

  submitFolder() {
    let params = new URLSearchParams();
    params.append('decodedPath', this.state.item.linkPath);
    // params.append('decodedPath', this.state.item.linkPath);
    params.append('userID', UserStore.getUserInfo().id);
    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/addFolder`, params)
      .then((response) => {
        if (response.data === 'logged_out') { UserStore.logOut(); }
        UserStore.updateCart(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  mouseOver() {
    this.setState({ iconClass: 'fas fa-folder-open'});
  }
  mouseOut() {
    this.setState({ iconClass: 'fas fa-folder'});
  }

  testFunc() {
    console.log(this.state.item.linkPath);
  }

  renderDlAll() {
    return (
      <a
        className="download-all"
        href="download-all"
        ref={(downloadAll) => { this.downloadAll = downloadAll; }}
      >
        <i className="fas fa-download" />
      </a>
    );
  }

  render() {
    // console.log(`${this.state.item.isZippable} - ${this.state.item.size}`);
    const link = `/${this.state.item.linkPath}`;
    let dlAll = false;
    if (
      this.state.item.isZippable ||
      (this.state.item.count.dirs === 0 && this.state.item.count.files > 0)
    ) {
      // console.log(this.state.item.name);
      dlAll = true;
    }

    return (
      <div className="title">
        <span
          ref={(folderLink) => { this.folderLink = folderLink; }}
        >
          <Link
            to={link}
            className="folder-link"
            onMouseOver={this.mouseOver}
            onMouseOut={this.mouseOut}
            onFocus={this.mouseOver}
          >
            <i className={`${this.state.iconClass} item-icon`} />
            <span className="ttl">{this.state.item.name}</span>
          </Link>
        </span>
        {dlAll ? this.renderDlAll() : ''}
      </div>
    );
  }
}

export default TitleFolder;
