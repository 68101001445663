import React, { Component } from 'react';
import axios from 'axios';
import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';
import LoginModal from './LoginModal';
import AccountModal from './AccountModal';
import DownloadCartModal from './DownloadCartModal';
import DownloadStatusModal from './DownloadStatusModal';
// import LoginInit from './LoginInit';
import UserNavigation from './UserNavigation';
import ClearCartModal from './ClearCartModal';
import DownloadDirModal from './DownloadDirModal';

export default class LoginHander extends Component {

  constructor() {
    super();
    this.state = {
      loginState: UserStore.getLoginState(),
      wpBase: UserStore.getWpBase(),
      allModals: ModalStore.getAllModals(),
      modalCount: ModalStore.getAllModals().length,
    };
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('loginState', () => {
      const currState = UserStore.getLoginState();
      if (currState === 'logged_out' && UserStore.getUserInfo() !== []) {
        // remove the expired user info:
        UserStore.setUserInfo([]);
      }
      this.setState({ loginState: currState });
    });
    this.emitter1 = ModalStore.addListener('allModals', () => {
      const allModals = ModalStore.getAllModals();
      this.setState({ allModals });
      // console.log(`${allModals.length} - ${this.state.modalCount}`);
      if (allModals.length === 0 && this.state.modalCount !== 0) {
        setTimeout(() => {
          this.setState({ modalCount: 0 });
        }, 250);
      } else {
        this.setState({ modalCount: allModals.length });
      }
    });

    this.checkHistory();
  }

  componentWillUnmount() {
    this.emitter0.remove();
    this.emitter1.remove();
  }

  checkHistory() {
    // console.log(`${this.state.wpBase}wp-json/slhgFTP/v1/history`);
    axios.get(`${this.state.wpBase}wp-json/slhgFTP/v1/history`)
      .then ((response) => {
        // console.log(response.data);
        if (response.data.id) {
          UserStore.setUserInfo(response.data);
          UserStore.setLoginState('logged_in');
        } else {
          UserStore.logOut();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    // console.log(this.state.allModals);
    const modalCount = this.state.modalCount;
    return (
      <div>
        <UserNavigation />
        <div className={modalCount === 0 ? 'no-modals' : 'modals'}>
          <AccountModal />
          <DownloadCartModal />
          <DownloadStatusModal />
          <LoginModal />
          {/* <LoginInit /> */}
          <ClearCartModal />
          <DownloadDirModal />
        </div>
      </div>
    );
  }
}
