import React, { Component } from 'react';
import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';

export default class CartDownload extends Component {

  constructor(props) {
    super(props);
    this.state = {
      cartArray: UserStore.getCartArray(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('cartArrayChange', () => {
      this.setState({ cartArray: UserStore.getCartArray(), });
    });
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    this.emitter0.remove();
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.zipFiles && this.zipFiles.contains(e.target)) {
      e.preventDefault();
      ModalStore.setModal('zip');
    }
  }

  render() {
    const contents = this.state.cartArray.contents;
    let count = 0;
    if (contents) { count = contents.length; }

    if (count === 0) {
      return <span className="cart-dl">0 files selected for download.</span>;
    }

    return (
      <a
        href="cart-dl"
        className="cart-dl link"
        ref={(zipFiles) => { this.zipFiles = zipFiles; }}
      >
        Download {count} files, {this.state.cartArray.sizeR} (unzipped)
      </a>
    );
  }
}
