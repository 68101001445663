import React, { Component } from 'react';
import axios from 'axios';
import UserStore from '../flux/UserStore';

export default class LoginForm extends Component {

  constructor() {
    super();
    this.state = {
      username: false,
      password: false,
      wpBase: UserStore.getWpBase(),
      loginState: UserStore.getLoginState(),
      failed: false,
    };
    this.handleClick = this.handleClick.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('loginState', () => {
      if (
        this.state.loginState === 'waiting' &&
        UserStore.getLoginState() === 'logged_out'
      ) {
        // console.log('Just errored out.');
        this.setState({ failed: true });
      } else if (
        UserStore.getLoginState() === 'waiting' &&
        this.state.failed
      ) {
        // remove old error message:
        this.setState({ failed: false });
      }
      this.setState({ loginState: UserStore.getLoginState() });
    });
    document.addEventListener('click', this.handleClick);
    this.username.focus();
  }
  //
  componentWillUnmount() {
      this.emitter0.remove();
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.submit && this.submit.contains(e.target)) {
      e.preventDefault();
      if (
        this.state.username !== '' &&
        this.state.password !== '' &&
        this.state.loginState !== 'waiting'
      ) {
        this.sendLogin();
      }
    }
  }

  sendLogin() {
    UserStore.setLoginState('waiting');
    let params = new URLSearchParams();
    params.append('username', this.state.username);
    params.append('password', this.state.password);

    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/login`, params)
      .then((response) => {
        if (response.data.id) {
          UserStore.setUserInfo(response.data);
          UserStore.setLoginState('logged_in');
        } else {
          UserStore.logOut();
        }
      })
      .catch((error) => {
        UserStore.setLoginState('error');
        console.log(error);
      });

  }

  handleChange(e) {
    if (e.target.name === 'username') {
      this.setState({ username: e.target.value });
    } else if (e.target.name === 'password') {
      this.setState({ password: e.target.value });
    }
  }

  showErrMsg() {
    return (
      <div className="err">
        <p>Invalid username and/or password.</p>
      </div>
    );
  }

  render() {
    return (
      <form className="login-form">
        <h1>Smart Living HG Downloads</h1>
        <label htmlFor="login-username">Email Address:</label>
        <input
          type="text"
          name="username"
          defaultValue=""
          id="login-username"
          onChange={this.handleChange}
          ref={(username) => { this.username = username; }}
        />
        <label htmlFor="login-password">Password:</label>
        <input
          type="password"
          name="password"
          className="password"
          defaultValue=""
          id="login-password"
          onChange={this.handleChange}
        />
        {this.state.failed ? this.showErrMsg() : ''}
        <input
          type="submit"
          value="Submit"
          className={this.state.loginState === 'waiting' ? 'waiting' : 'btn'}
          ref={(submit) => { this.submit = submit; }}
        />
        <a href={`${this.state.wpBase}wp-login.php?action=lostpassword`}>
          forgot password?
        </a>
      </form>
    );
  }
}
