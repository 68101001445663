import React, { Component } from 'react';
import BrowserHeader from './BrowserHeader';
import SelectedContents from './SelectedContents';
import Error404 from './Error404';
import BrowseFooter from './BrowseFooter';
import CartPage from './CartPage';
// import ZipCleanup from './ZipCleanup';
import DiagnosticPage from './DiagnosticPage';
import DirStore from '../flux/DirStore';

export default class SelectedDir extends Component {

  constructor() {
    super();

    this.state = {
      currentList: false,
    };
  }

  componentDidMount() {
    this.emitter0 = DirStore.addListener('loadedDir', () => {
      this.setState({ currentList: DirStore.getCurrentLoadedDir() });
    });
  }

  componentWillUnmount() {
    this.emitter0.remove();
  }

  render() {
    if (this.state.currentList.linkPath === 'diagnostic') {
      return (
        <div className="diagnostic-page">
          <DiagnosticPage />
        </div>
      );
    } else if (this.state.currentList.linkPath === 'cart') {
      return (
        <div className="selected-dir cart-page">
          <BrowserHeader />
          <CartPage />
          <BrowseFooter />
        </div>
      );
    } else if (this.state.currentList) {
      const type = this.state.currentList.type;
      return (
        <div className="selected-dir">
          <BrowserHeader />
          {type === 'dir' ? <SelectedContents /> : ''}
          {type === '404' ? <Error404 /> : ''}
          <BrowseFooter />
        </div>
      );
    } else {
      return 'loading screen.';
    }
  }
}
