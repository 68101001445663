import React, { Component } from 'react';
// import axios from 'axios';
// import UserStore from '../flux/UserStore';
import ModalStore from '../flux/ModalStore';
// import LoginForm from './LoginForm';
import AccountModalContent from './AccountModalContent';

export default class AccountModal extends Component {

  constructor() {
    super();
    this.state = {
      modalName: 'account',
      modalState: 'off',
      content: 'off',
    };
    this.handleKeyboard = this.handleKeyboard.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keyup', this.handleKeyboard);
    this.emitter0 = ModalStore.addListener('allModals', () => {
      this.checkModals(ModalStore.getAllModals());
    });
  }

  componentWillUnmount() {
    document.removeEventListener('keyup', this.handleKeyboard);
    this.emitter0.remove();
  }

  checkModals(allModals) {
    let haveModal = false;
    for (let i = 0; i < allModals.length; i = i + 1) {
      if (allModals[i] === this.state.modalName) {
        this.setState({
          modalState: 'on',
          content: 'on',
        });
        haveModal = true;
      }
    }
    if (!haveModal && this.state.content === 'on') {
      this.closeModal();
    }
  }

  handleKeyboard(e) {
    if (this.state.modalState === 'on' && e.keyCode === 27) {
      // this.closeModal();
      ModalStore.removeModal(this.state.modalName);
    }
  }

  closeModal() {
    this.setState({ content: 'off' });
    setTimeout(() => {
      this.setState({ modalState: 'off' });
    }, 250);
  }

  render() {
    return (
      <span className={this.state.modalState === 'off' ? 'modal-off' : 'modal'}>
        <div
          className={
            this.state.content === 'off' ?
            'modal-bg-off' :
            'modal-bg modal-bg-login'
          }
        >
          {
            this.state.content === 'on' ?
            <AccountModalContent modalName={this.state.modalName} /> :
            ''
          }
        </div>
      </span>
    );
  }
}
