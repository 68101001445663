import React, { Component } from 'react';
import DirStore from '../flux/DirStore';

export default class BrowseFooter extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      currentDir: DirStore.getCurrentLoadedDir(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.emitter0 = DirStore.addListener('loadedDir', () => {
      this.setTitle();
      this.setState({ title: DirStore.getCurrentLoadedDir().name });
    });
    document.addEventListener('click', this.handleClick);
    this.setTitle();
  }

  componentWillUnmount() {
    this.emitter0.remove();
    document.removeEventListener('click', this.handleClick);
  }

  setTitle() {
    if (this.state.currentDir.linkPath === '') {
      this.setState({ title: 'FTP' });
    } else {
      this.setState({ title: DirStore.getCurrentLoadedDir().name });
    }
  }

  handleClick(e) {
    if (this.rootLink && this.rootLink.contains(e.target)) {
      e.preventDefault();
      DirStore.setSelectedDir('');
    }
  }

  render() {
    const year = new Date().getFullYear();
    return (
      <footer className="browser-footer">
        <div className="copyright">
          <a
            href="https://smartlivinghg.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="far fa-copyright" /> {year} SmartLivingHG.com
          </a>
        </div>
      </footer>
    );
  }
}
