import React, { Component } from 'react';
import ScanFTP from './components/ScanFTP';
import LoginHandler from './components/LoginHandler';
import './sass/style.scss';

class App extends Component {
  render() {
    return (
      <div className="App">
        <ScanFTP />
        <LoginHandler />
      </div>
    );
  }
}

export default App;
