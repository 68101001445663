import React, { Component } from 'react';
// import DirStore from '../flux/DirStore';
import UserStore from '../flux/UserStore';
// import LogOutButton from './LogOutButton';
import AccountButton from './AccountButton';
// import CartButton from './CartButton';

export default class UserNavigation extends Component {

  constructor() {
    super();
    this.state = {
      userInfo: UserStore.getUserInfo(),
    };
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('userInfo', () => {
      this.setState({ userInfo: UserStore.getUserInfo() });
    });
  }

  componentWillUnmount() {
    this.emitter0.remove();
  }

  render() {
    if (this.state.userInfo.id) {
      return (
        <nav className="user-info">
          <div className="user-icon-bar">
            <div className="user-icon-buttons">
              <AccountButton />
            </div>
          </div>
        </nav>
      );
    }
    return false;
  }
}
