import React, { Component } from 'react';
import axios from 'axios';
import DiagnosticStore from '../flux/DiagnosticStore';
import UserStore from '../flux/UserStore';
import ZipCleanup from './ZipCleanup';

export default class DiagnosticPage extends Component {

  constructor() {
    super();

    this.state = {
      wpBase: UserStore.getWpBase(),
      currentList: false,
      allDirs: false,
      allZipFiles: [],
      toDelete: [],
    };
    this.loadDir();
  }

  componentDidMount() {
    this.emitter0 = DiagnosticStore.addListener('allDirs', () => {
      this.setState({ allDirs: DiagnosticStore.getAllDirs() });
      this.findOldZipFiles();
    });
    this.emitter1 = DiagnosticStore.addListener('existingZips', () => {
      this.setState({
        allZipFiles: DiagnosticStore.getExistingZips(),
        toDelete: this.findZipToDelete(DiagnosticStore.getExistingZips()),
      });
      console.log(this.state.currentList);
    });
  }

  componentWillUnmount() {
    this.emitter0.remove();
    this.emitter1.remove();
  }

  loadDir(path) {
    let dir = new URLSearchParams();
    dir.append('path', '');

    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/getDir`, dir)
      .then((response) => {
        DiagnosticStore.setAllDirArray(response.data);
      }).catch((error) => {
        console.log(error);
      });
  }

  findOldZipFiles() {
    let dir = new URLSearchParams();
    dir.append('allDirs', this.state.allDirs);

    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/findOldZipFiles`, dir)
      .then((response) => {
        DiagnosticStore.setUnreachable(this.getZipNames(response.data));
      }).catch((error) => {
        console.log(error);
      });
  }

  findZipToDelete(allExisting) {
    let toDelete = [];
    if (this.state.allDirs.contents) {
      const allPossibleZip = this.getZipNames(this.state.allDirs.contents);

      let i;
      for (i = 0; i < allExisting.length; i = (i + 1)) {
        if (!allPossibleZip.includes(allExisting[i])) {
          toDelete.push(allExisting[i]);
        }
      }
    }
    console.log(toDelete);
    return toDelete;
  }

  getZipNames(dir) {
    let i;
    let result = [];
    for (i = 0; i < dir.length; i= (i + 1)) {
      if (dir[i].type === 'dir') {
        result.push(dir[i].zipPath);
        if (dir[i].count && dir[i].count.dirs > 0) {
          // console.log(dir[i].count.dirs);
          const childs = this.getZipNames(dir[i].contents);
          result = [...result, ...childs];
        }
      }
    }
    return result;
  }

  renderDirTree(contents) {
    return (
      <div>
        <ul className="dir-tree">
          {contents.map((dir) => {
            if (dir.type === 'dir') {
              // console.log(dir.isZipped);
              const date = new Date(dir.mktime * 1000);
              let minutes = date.getMinutes();
                  minutes = (minutes < 10 ? '0' : '') + minutes;
              const day =
                `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
              const time = `${date.getHours()}:${minutes}`;
              let children = false;
              if (dir.count && dir.count.dirs > 0) { children = true; }
              return (
                <li
                  key={dir.linkPath}
                  className={dir.isZipped ? 'zipped' : 'not-zipped'}
                >
                  <span className={dir.isZippable ? 'zippable' : 'not-zippable'}>
                    {dir.name} - {day} {time}
                    {dir.isZippable ? '' : ' (too large to zip)'}
                    {children ? this.renderDirTree(dir.contents) : ''}
                  </span>
                </li>
              );
            }
            return null;
          }, this)}
        </ul>
      </div>
    );
  }

  render() {
    if (this.state.allDirs) {
      return (
        <span>
          <ZipCleanup />
          {this.renderDirTree(this.state.allDirs.contents)}
        </span>
      );
    }
    return 'diagnostic page for cache management';
  }
}
