import React, { Component } from 'react';

export default class Error404 extends Component {

  constructor() {
    super();
    this.state = {
      // contents: DirStore.getCurrentLoadedDir(),
    };
  }

  render() {
    return (
      <div className="err-404">
        The folder you are looking for is not available.
      </div>
    );
  }
}
