import React, { Component } from 'react';
// import { BrowserRouter as Router, Link } from "react-router-dom";
// import DirStore from '../flux/DirStore';
import ModalStore from '../flux/ModalStore';

export default class DownloadCartZipButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      oldModal: props.oldModal,
      title: props.title,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if ( this.zip && this.zip.contains(e.target) ) {
      e.preventDefault();
      // ModalStore.removeModal(this.state.oldModal);
      ModalStore.setModal('zip');
      // ModalStore.removeModal(this.state.modalName);
      // DirStore.setSelectedDir(this.state.modalName);
    }
  }

  render() {
    return (
      <button
        ref={(zip) => { this.zip = zip; }}
      >
        {this.state.title}
      </button>
    );
  }
}
