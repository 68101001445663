import { EventEmitter } from 'fbemitter';

const emitter = new EventEmitter();
let allModals = ['init'];

const ModalStore = {
  setModal(modalName) {
    allModals.push(modalName);
    emitter.emit('allModals');
  },

  removeModal(modalName) {
    for (var i = 0; i < allModals.length; i++) {
      if (allModals[i] === modalName) {
        allModals.splice(i, 1);
      };
    }
    emitter.emit('allModals');
  },

  modalReset() {
    // reset modal on log in to close
    // any modals that were open on previous login
    allModals = [];
    emitter.emit('allModals');
  },

  getAllModals(): Array<Object> { return allModals; },

  addListener(eventType: string, fn: Function) {
    return emitter.addListener(eventType, fn);
  },
};

export default ModalStore;
