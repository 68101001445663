import React, { Component } from 'react';
import DirStore from '../flux/DirStore';

export default class SortButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      title: props.title,
      sortBy: DirStore.getSortBy(),
      sortDirection: DirStore.getSortDirection(),
      selected: false,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    if (this.checkSelected()) { this.setState({ selected: true }); };
    this.emitter1 = DirStore.addListener('sortBy', () => {
      const sortBy = DirStore.getSortBy();
      const selected = this.checkSelected();
      this.setState({ sortBy, selected, });
    });
    this.emitter2 = DirStore.addListener('sortDirection', () => {
      this.setState({ sortDirection: DirStore.getSortDirection() });
    });
    document.addEventListener('click', this.handleClick);
  }
  //
  componentWillUnmount() {
    this.emitter1.remove();
    this.emitter2.remove();
    document.removeEventListener('click', this.handleClick);
  }

  checkSelected() {
    return this.state.title === DirStore.getSortBy();
  }

  handleClick(e) {
    if (this.sort && this.sort.contains(e.target)) {
      e.preventDefault();
      if (this.state.title !== this.state.sortBy) {
        DirStore.setSortBy(this.state.title);
        DirStore.setSortDirection('low2high');
      } else {
        DirStore.reverseSortDirection();
      }
    }
  }

  renderArrow() {
    if (this.state.sortDirection === 'low2high') {
      return <i className="fas fa-angle-up" />;
    }
    return <i className="fas fa-angle-down" />;
  }

  render() {
    return (
      <span
        className={this.state.title}
        ref={(sort) => { this.sort = sort; }}
      >
        <a href="sort">
          {this.state.title}
          {this.state.selected ? this.renderArrow() : ''}
        </a>
      </span>
    );
  }
}
