import React, { Component } from 'react';
import { BrowserRouter as Router } from "react-router-dom";
// import axios from 'axios';
import DirStore from '../flux/DirStore';
import UserStore from '../flux/UserStore';
import SortList from '../actions/SortList';
import SortButton from './SortButton';
import TitleFolder from './TitleFolder';
import CartFile from './CartFile';
import CartDownload from './CartDownload';
import ClearCartButton from './ClearCartButton';

export default class CartPage extends Component {

  constructor() {
    super();
    this.state = {
      wpBase: UserStore.getWpBase(),
      cartArray: UserStore.getCartArray(),
      cartCount: UserStore.getCart(),
      userInfo: UserStore.getUserInfo(),
      sortBy: DirStore.getSortBy(),
      sortDirection: DirStore.getSortDirection(),
    };
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('cartChange', () => {
      this.monitorCart();
      this.setState({ cartArray: UserStore.getCartArray() });
    });
    this.emitter1 = UserStore.addListener('cartArrayChange', () => {
      this.monitorCart();
      this.setState({ cartArray: UserStore.getCartArray() });
    });
    this.emitter2 = DirStore.addListener('sortBy', () => {
      this.checkSorting();
    });
    this.emitter3 = DirStore.addListener('sortDirection', () => {
      this.checkSorting();
    });

    this.emitter4 = UserStore.addListener('userInfo', () => {
      this.setState({ userInfo: UserStore.getUserInfo() });
    });
  }

  componentWillUnmount() {
    this.emitter0.remove();
    this.emitter1.remove();
    this.emitter2.remove();
    this.emitter3.remove();
    this.emitter4.remove();
  }

  monitorCart() {
    this.setState({
      cartArray: UserStore.getCartArray(),
      cartCount: UserStore.getCart(),
    });
  }

  checkSorting() {
    const sortBy = DirStore.getSortBy();
    const sortDirection = DirStore.getSortDirection();
    if (
      this.state.sortBy !== sortBy ||
      this.state.sortDirection !== sortDirection
    ) {
      this.setState({ sortBy, sortDirection });
    }
  }

  render() {
    const list = SortList.arrange(
      this.state.cartArray.contents,
      this.state.sortBy,
      this.state.sortDirection,
    );
    let cartCount = this.state.cartCount.length;
    return (
      <section className="cart-page">
        <CartDownload />
        {cartCount > 0 ? <ClearCartButton /> : ''}
        <span className="sort-by">
          <SortButton title="name" />
          <SortButton title="type" />
          <SortButton title="size" />
        </span>
        <Router>
          <ul>
            {list.map((item) => {
              return (
                <li key={item.linkPath}>
                  {
                    item.extension === 'folder' ?
                    <TitleFolder item={item} /> :
                    <CartFile item={item} />
                  }
                  <span className="type">{item.extension}</span>
                  <span className="size">{item.sizeR}</span>
                </li>
              );

            }, this)}
          </ul>
        </Router>
      </section>
    );
  }
}
