import React, { Component } from 'react';
import DirStore from '../flux/DirStore';
import Breadcrumb from './Breadcrumb';

export default class Breadcrumbs extends Component {

  constructor(props) {
    super(props);
    this.state = {
      crumbArray: DirStore.getCurrentLoadedDir().breadcrumbs,
    };
  }

  componentDidMount() {
    this.emitter0 = DirStore.addListener('loadedDir', () => {
      this.setState({ crumbArray: DirStore.getCurrentLoadedDir().breadcrumbs });
    });
  }

  componentWillUnmount() {
    this.emitter0.remove();
  }

  render() {
    return (
      <span>
        {this.state.crumbArray.map((crumb) => {
          return <Breadcrumb crumb={crumb} key={crumb.link} />;
        }, this)}
      </span>
    );
  }
}
