import React, { Component } from 'react';
import axios from 'axios';
import DirStore from '../flux/DirStore';
import UserStore from '../flux/UserStore';
import SelectedDir from './SelectedDir';
import PathFromURL from '../actions/PathFromURL';

export default class ScanFTP extends Component {

  constructor() {
    super();

    this.state = {
      wpBase: UserStore.getWpBase(),
      selectedDir: DirStore.getSelectedDir(),
      currentLoadedDir: undefined,
    };
  }

  componentDidMount() {
    this.emitter0 = DirStore.addListener('loadDir', () => {
      const dirArray = DirStore.getAllDirs();
      if (dirArray[DirStore.getSelectedDir()]) {
        DirStore.setCurrentLoadedDir(dirArray[DirStore.getSelectedDir()]);
      }
    });

    this.emitter1 = DirStore.addListener('selectedDir', () => {
      this.loadDir( DirStore.getSelectedDir() );
    });

    window.onpopstate = this.browserChangeURL;

    // run first ajax request
    this.browserChangeURL();
  }

  componentWillUnmount() {
    this.emitter0.remove();
    this.emitter1.remove();
  }

  browserChangeURL() {
    DirStore.setSelectedDir(PathFromURL.getPathURL());
  }

  loadDir(path) {
    let dir = new URLSearchParams();
    dir.append('path', path);
    // console.log(path);
    console.log(`${this.state.wpBase}wp-json/slhgFTP/v1/getDir`);
    console.log(dir);
    axios.post(`${this.state.wpBase}wp-json/slhgFTP/v1/getDir`, dir)
      .then((response) => {
        if (response.data) {
          DirStore.setDirContents(response.data);
          console.log(response.data);
        }
      }).catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <section className="browser">
        <SelectedDir />
      </section>
    );
  }
}
