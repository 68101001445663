import React, { Component } from 'react';
import CloseModalButton from './CloseModalButton';
import CloseModalCartButton from './CloseModalCartButton';
import DownloadCartZipButton from './DownloadCartZipButton';
import ModalStore from '../flux/ModalStore';
import UserStore from '../flux/UserStore';

export default class DownloadCartModalContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
      userInfo: UserStore.getUserInfo(),
      cartArray: UserStore.getCartArray(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    this.emitter0 = UserStore.addListener('cartArrayChange', () => {
      this.setState({ cartArray: UserStore.getCartArray(), });
    });
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    this.emitter0.remove();
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (
      this.bgBtn && e.target === this.bgBtn
    ) {
      e.preventDefault();
      ModalStore.removeModal(this.state.modalName);
    }
  }

  render() {
    const contents = this.state.cartArray.contents;
    let count = 0;
    if (contents) { count = contents.length; }

    return (
      <div className="modal-content">
        <div
          className="bg-close-btn"
          ref={(bgBtn) => { this.bgBtn = bgBtn; }}
        />
        <span className="user-modal">
          <ul>
            <li>
              <DownloadCartZipButton
                oldModal={this.state.modalName}
                title={`Collect ${count} files for download`}
              />
            </li>
            <li>{this.state.cartArray.sizeR} (unzipped)</li>
            <li>
              <CloseModalCartButton modalName={this.state.modalName} />
            </li>
          </ul>
          <CloseModalButton modalName={this.state.modalName} />
        </span>
      </div>
    );
  }
}
