import { EventEmitter } from 'fbemitter';

const emitter = new EventEmitter();
let allDirs = [];
let unReachableDirs = [];
let existingZips = [];
// let sortBy = 'name';
// let sortDirection = 'low2high';
// let dirToDownload = []; // selected dir to download

const DirStore = {
  setAllDirArray(array) {
    allDirs = array;
    emitter.emit('allDirs');
  },
  getAllDirs(): Array<Object> { return allDirs; },

  setExistingZips(array) {
    existingZips = array;
    emitter.emit('existingZips');
  },
  getExistingZips(): Array<Object> { return existingZips; },

  setUnreachable(array) {
    unReachableDirs = array;
    emitter.emit('unReachableDirs');
  },
  getUnreachable(): Array<Object> { return unReachableDirs; },

  addListener(eventType: string, fn: Function) {
    return emitter.addListener(eventType, fn);
  },
};

export default DirStore;
