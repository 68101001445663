import React, { Component } from 'react';
import axios from 'axios';
import UserStore from '../flux/UserStore';
// import ModalStore from '../flux/ModalStore';

export default class LogOutButton extends Component {

  constructor() {
    super();
    // console.log(props.item.linkPath);
    this.state = {
      wpBase: UserStore.getWpBase(),
      userInfo: UserStore.getUserInfo(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }
  //
  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.logout && this.logout.contains(e.target)) {
      e.preventDefault();
      this.logUserOut();
    }
  }

  logUserOut() {
    // console.log(`${this.state.wpBase}logout`);
    // axios.get(`${this.state.wpBase}wp-json/slhgFTP/v1/logout`)
    axios.get(`${this.state.wpBase}?action=logout`)
      .then ((response) => {
        if (response.data === 'logged_out') { UserStore.logOut(); }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    return (
      <span className="logout">
        <button
          className="logout"
          ref={(logout) => { this.logout = logout; }}
        >
          log out
        </button>
      </span>
    );
  }
}
