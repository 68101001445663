import React, { Component } from 'react';
import DirStore from '../flux/DirStore';
import { Link } from "react-router-dom";

export default class Breadcrumb extends Component {

  constructor(props) {
    super(props);
    this.state = {
      crumb: props.crumb,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (this.crumb && this.crumb.contains(e.target)) {
      e.preventDefault();
      if (DirStore.getSelectedDir() !== this.state.crumb.link) {
        DirStore.setSelectedDir(this.state.crumb.link);
      }
    }
  }

  render() {
    let currentLink = true;
    if (DirStore.getSelectedDir() !== this.state.crumb.link) {
      currentLink = false;
    }
    const link = `/${this.state.crumb.link}`;
    return (
      <span ref={(crumb) => { this.crumb = crumb; }}>
        <Link
          to={link}
          ref={(crumb) => { this.crumb = crumb; }}
          className={currentLink ? 'current' : 'crumb'}
        >
          {this.state.crumb.name}
        </Link>
      </span>
    );
  }
}
