import React, { Component } from 'react';
import { BrowserRouter as Router, Link } from "react-router-dom";
import DirStore from '../flux/DirStore';
import ModalStore from '../flux/ModalStore';

export default class CloseModalCartButton extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if ( this.closeBtn && this.closeBtn.contains(e.target) ) {
      e.preventDefault();
      ModalStore.removeModal(this.state.modalName);
      DirStore.setSelectedDir(this.state.modalName);
    }
  }

  render() {
    return (
      <span
        ref={(closeBtn) => { this.closeBtn = closeBtn; }}
      >
        <Router>
          <Link to="/cart">Review files for download</Link>
        </Router>
      </span>
    );
  }
}
