
const IconClass = {
  getClassName(extension) {
    let iconClassImg = 'fas fa-image';
    switch (extension) {
      case 'folder':
        return 'fas fa-folder';
      case 'jpg':
        return iconClassImg;
      case 'gif':
        return iconClassImg;
      case 'png':
        return iconClassImg;
      case 'bmp':
        return iconClassImg;
      case 'mp4':
        return 'fas fa-film';
      case 'mov':
        return 'fas fa-film';
      case 'pdf':
        return 'fas fa-file-pdf';
      case 'pptx':
        return 'fas fa-file-powerpoint';
      case 'zip':
        return 'fas fa-file-archive';
      default:
        return 'fas fa-file';
    }
  },
};

export default IconClass;
