import React, { Component } from 'react';
import ModalStore from '../flux/ModalStore';
import UserStore from '../flux/UserStore';
import LogOutButton from './LogOutButton';

export default class AccountModalContent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modalName: props.modalName,
      wpBase: UserStore.getWpBase(),
      userInfo: UserStore.getUserInfo(),
    };
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClick);
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleClick);
  }

  handleClick(e) {
    if (
      (this.closeBtn && this.closeBtn.contains(e.target)) ||
      (this.bgBtn && e.target === this.bgBtn)
    ) {
      e.preventDefault();
      ModalStore.removeModal(this.state.modalName);
    }
  }

  renderProfileLink() {
    return (
      <li>
        <a href={`${this.state.wpBase}wp-admin/profile.php`}>
          account information
        </a>
      </li>
    );
  }

  render() {
    let admin = false;
    if (this.state.userInfo.roles[0] === 'administrator') { admin = true; }
    const user = this.state.userInfo;
    return (
      <div className="modal-content">
        <div
          className="bg-close-btn"
          ref={(bgBtn) => { this.bgBtn = bgBtn; }}
        />
        <span className="user-modal">
          <ul>
            <li><h1>{user.email}</h1></li>
            {admin ? this.renderProfileLink() : ''}
            <li><LogOutButton /></li>
          </ul>
          <a
            href="close"
            ref={(closeBtn) => { this.closeBtn = closeBtn; }}
            className="close-button"
          >
            <i className="far fa-times-circle" />
          </a>
        </span>
      </div>
    );
  }
}
